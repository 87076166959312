/* start ONETRUST styles */
/* onetrust cookie icon fixed at bottom left of screen */
#ot-sdk-btn-floating.ot-floating-button.ot-pc-open.ot-floating-button__back,
#ot-sdk-btn-floating {
  visibility: hidden !important;
}

/* hide onetrust logo in "do not sell" modal */
.ot-pc-footer-logo {
  display: none !important;
}

/* onetrust modal style */
#onetrust-pc-sdk {
  border-radius: 12px !important;
}

#onetrust-pc-sdk a {
  color: #00789e !important;
}

/* hide most cookie categories that are not toggleable */
div.ot-accordion-layout[data-optanongroupid='C0001'],
div.ot-accordion-layout[data-optanongroupid='C0002'],
div.ot-accordion-layout[data-optanongroupid='C0003'],
div.ot-accordion-layout[data-optanongroupid='BG9'],
.ot-acc-grpcntr.ot-acc-txt {
  display: none !important;
}

#ot-category-title {
  display: none !important;
}

/* outside CA */
#onetrust-pc-sdk:not(:has(.ot-tgl)) .ot-sdk-row.ot-cat-grp,
#onetrust-pc-sdk:not(:has(.ot-tgl)) > div > .ot-pc-footer {
  display: none !important;
}

#onetrust-pc-sdk:not(:has(.ot-tgl)) #ot-pc-content {
  bottom: 0 !important;
}

/* inside CA */
div.ot-accordion-layout.ot-cat-item.ot-vs-config {
  border: 0 !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk *:focus,
.ot-tgl > input.category-switch-handler:focus,
label.ot-switch,
label.ot-switch:focus {
  outline: none !important;
}

.ot-plus-minus {
  display: none !important;
}

/* make accordion text below toggle always visible */
div.ot-acc-grpcntr.ot-acc-txt {
  display: block !important;
  background-color: white !important;
  overflow: visible !important;
  opacity: 1 !important;
}

p#ot-desc-id-BG8.ot-acc-grpdesc.ot-category-desc {
  display: block !important;
  padding-left: 0 !important;
}

/* styles for green bar at top when opted out */
#onetrust-consent-sdk .ot-optout-signal {
  display: flex !important;
  align-items: center !important;
}

#onetrust-consent-sdk .ot-optout-icon > svg {
  transform-origin: top !important;
}

/* end ONETRUST styles */
