.duke-maps header {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #005884;
}

.duke-maps header .map-filter-list {
  display: block !important;
  position: absolute;
  visibility: hidden;
  z-index: 10;
  right: -1px;
  border: 1px solid #fff;
}

.duke-maps header .map-filter-list.dropdown-display {
  visibility: visible;
}

.duke-maps .map-expand-app {
  display: block !important;
  position: absolute;
  height: 30px;
  width: 30px;
  top: 3px;
  right: -20px;
  color: #fff;
  padding: 6px;
  cursor: pointer;
  /* background: rgba(255, 255, 255, 0.2) url('../img/icons/icon-expand.svg') no-repeat 6px; */
  background-size: 30px;
}

.duke-maps .map-search {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .duke-maps .map-search input[type='text'] {
    color: white;
  }
  .duke-maps .map-search input[type='text']:focus {
    outline: none;
  }
  .map-content:has(.map-panels) {
    height: auto !important;
  }
}

.duke-maps .map-search button:hover {
  background-color: #f2f4f4;
}

.duke-maps .map-search button:before {
  display: none;
  content: '';
}

.duke-maps .map-search-utility {
  margin-top: 10px;
  margin-bottom: 0px !important;
}

.duke-maps .map-search-utility .map-search-use-current {
  color: #f1f1f1 !important;
}

.duke-maps .map {
  position: relative;
  display: block !important;
  width: 100%;
  min-height: 500px;
  background-color: #e2eaed !important;
}

.duke-maps .map-content {
  position: relative;
  min-height: 500px;
  height: 500px;
  transition: height 0.5s ease-in-out;
}

.duke-maps .map-content .angular-google-map {
  display: block !important;
  overflow: hidden;
}

.duke-maps .map-content .angular-google-map .angular-google-map-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.duke-maps .map-content .angular-google-map.sb-open .angular-google-map-container {
  width: 76%;
}

.duke-maps .map-content .angular-google-map.locked {
  height: 100%;
}

.duke-maps.map-type-filtered-search .map-search button {
  width: 13%;
}

.duke-maps.map-type-filtered-search .map-search input[type='text'] {
  width: 45%;
}
